import React from "react";
import "../css/projects.css";

import engine from "../img/engine.jpg";
import mercury from "../img/525mercury.jpeg";
import chevelle from "../img/72blackchevelle.jpeg";
import chevy from "../img/55chevy.jpeg";
import SBC from "../img/385SBC.jpg";
import HEMI from "../img/streetHemi.jpeg";

const projectsData = [
  {
    id: 1,
    title: "525 Mercury High Performance Engines",
    description: "Stats: 610 HP , 610 Torque | Built with: Isky Valve Springs, Johnson Lifters, Total Seal Rings, Manley Valves, Bullet Cams, ATI Dampner",
    image: mercury,
  },
  {
    id: 2,
    title: "'81 Camero",
    description: "632 Powerglide T/S | Built wtih: Comp Cams Cam, Total Seal Rings, Manley Valves, Stef's, Jesel Belt Drive, T&D Rockers, Profiler Heads & Intake, Gary Williams Carb",
    image: engine,
  },
  {
    id: 3,
    title: "385 SBC",
    description: "Stats: 600 HP | Built with: Whipple Supercharger, Holly EFI, Comp Cams Cam, Icon Pistons, Total Seal Rings, Liberty Heads, Manley Valves, Isky Valve Springs, NGK plugs, T&D Rockers ",
    image: SBC,
  },
  {
    id: 4,
    title: "'72 Chevelle'",
    description: "New Arrival: 383 Small Block Chevy! The customer is looking for an engine refresh. Stay tuned for project updates! ",
    image: chevelle,
  },
  {
    id: 5,
    title: "'55 Chevy'",
    description: "Completed major restorations for this project, Upgraded the differential, Posi Traction, 5 Speed Conversion, Complete tubular front suspension system, Rack & pinion steering, Electric Brakes, 4 Wheel disc brakes, Surpetine drive system, Engine freshen, Overhaul of wiring, and Sealed battery & trunk. ",
    image: chevy,
  },
  {
    id: 6,
    title: "572 Cubic Inch Street HEMI",
    description: "Built with: Ray Barton Cylinder Head System, AED Carb, Callies Crank, JE Pistons, Oliver Rods, Total Seal Rings, T&D Rockers, Comp Cams Cam",
    image: HEMI,
  },
  // Add more project objects as needed
];

const Projects = () => {
  return (
    <div className="container mt-4">
      <h1 className="text-center">Projects</h1>
      <hr />
      <div className="row">
        {projectsData.map((project) => (
          <div key={project.id} className="col-lg-4 col-md-6 mb-4">
            <div className="card h-100">
              <img
                src={project.image}
                className="card-img-top"
                alt={project.title}
              />
              <div className="card-body">
                <h5 className="card-title">{project.title}</h5>
                <p className="card-text">{project.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Projects;
