import React from "react";
import { Link } from "react-router-dom";
import HeroImage from "../img/redplymouth.JPG";
import "../css/home.css"; // Import the CSS file

const Home = () => {
  return (
    <div className="home">
      <div className="hero-section">
        <img src={HeroImage} alt="Hero" className="hero-image" />
        <div className="hero-content">
          <h1 className="hero-title">Welcome to Benney's Custom Engines</h1>
          <p className="hero-description">
            Your destination for high-performance solutions.
          </p>
          <h2 className="description-title">Who We Are</h2>
          <p className="description-text">
            BCE is a leading provider of high-performance solutions for
            automotive enthusiasts. With over 30 years of experience, we
            specialize in precision engine machining, engine rebuilding, dyno
            testing, and more. Our passion for delivering exceptional quality
            and performance sets us apart. Discover how we can help you unleash
            the full potential of your vehicle.
          </p>
          <Link to="/services" className="btn btn-primary btn-lg">
            Explore Services
          </Link>
        </div>
      </div>
    </div>
  );
  };

export default Home;