import React, { useRef, useState } from "react";
import emailjs from "emailjs-com";
import {
  FaMapMarkerAlt,
  FaPhone,
  FaEnvelope,
  FaFacebook,
  FaInstagram,
} from "react-icons/fa";

const Contact = () => {
  const form = useRef();

  const SERVICE_ID = "service_qqto33s"; // Replace with your EmailJS service ID
  const TEMPLATE_ID = "template_m1h8n3k"; // Replace with your EmailJS template ID
  const USER_ID = "cpC6Na0OpDYdq8y8t"; // Replace with your EmailJS user ID

  const address = "7300 Westmore Road Unit 5, Rockville, MD, 20850";
  const phoneNumber = "(301) 461-5717";
  const emailAddress = "enginebuilding@gmail.com";
  const businessHours = "Mon-Fri: 9:00 AM - 5:00 PM";

  const [formSubmitted, setFormSubmitted] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, form.current, USER_ID).then(
      (result) => {
        console.log(result.text);
        setFormSubmitted(true); // Set the formSubmitted state to true
      },
      (error) => {
        console.log(error.text);
      }
    );
  };

  return (
    <div className="container mt-4">
      <h1 className="text-center">Contact Us</h1>
      <hr />

      {/* Contact Information */}
      <div className="row">
        <div className="col-md-6">
          <h4>Contact Information</h4>
          <p>
            <FaMapMarkerAlt /> {address}
          </p>
          <p>
            <FaPhone /> {phoneNumber}
          </p>
          <p>
            <FaEnvelope /> <a href={`mailto:${emailAddress}`}>{emailAddress}</a>
          </p>
          <p>Business Hours: {businessHours}</p>
        </div>

        {/* Contact Form */}
        <div className="col-md-6">
          <h4>Contact Us</h4>
          {formSubmitted ? ( // Display a "Thank you" message if formSubmitted is true
            <div className="alert alert-success">
              Thank you for reaching out to Benney's Custom Engines. We will be
              in touch as soon as possible.
            </div>
          ) : (
            <form ref={form} onSubmit={sendEmail}>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Your Name"
                  name="user_name" // Add the 'name' attribute
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Your Email"
                  name="user_email" // Add the 'name' attribute
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Subject"
                  name="email_subject" // Add the 'name' attribute
                />
              </div>
              <div className="form-group">
                <textarea
                  className="form-control"
                  rows="4"
                  placeholder="Your Message"
                  name="message" // Add the 'name' attribute
                  required
                />
              </div>
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </form>
          )}
        </div>
      </div>

      {/* Location Map */}
      <div className="row mt-4">
        <div className="col-md-12">
          <h4>Location</h4>
          {/* Embed a map or provide a link to a map service like Google Maps */}
          <div className="embed-responsive embed-responsive-16by9">
            <iframe
              className="embed-responsive-item"
              title="Company Location"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3096.308775981088!2d-77.15086148788279!3d39.09943977156361!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b7cd1195ad8ac3%3A0x7fa4028847c88054!2sBenney&#39;s%20Custom%20Engines!5e0!3m2!1sen!2sus!4v1696592375093!5m2!1sen!2sus"
            />
          </div>
        </div>
      </div>

      {/* Social Media Links */}
      <div className="row mt-4">
        <div className="col-md-12">
          <h4>Connect with Us</h4>
          <ul className="list-inline">
            <li className="list-inline-item">
              <a
                href="https://www.facebook.com/benneycustomengines1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebook />
              </a>
            </li>
            <li className="list-inline-item">
              <a
                href="https://www.instagram.com/benneyscustomengines"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Contact;

