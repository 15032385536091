import React from "react";
import AboutImage from "../img/rpengine.JPG";

const About = () => {
  return (
    <div className="container mt-4">
      <h1 className="text-center">About Us</h1>
      <hr />
      <div className="row">
        <div className="col-md-6">
          <img
            src={AboutImage}
            alt="About Us"
            className="img-fluid mb-4"
            style={{ maxWidth: "100%" }} // Ensure the image is responsive
          />
        </div>
        <div className="col-md-6">
          <h4>Our Background</h4>
          <p>
            Benney's Custom Engines has been a trusted local establishment since
            1987, providing exceptional service and repairs for high-performance
            needs. With a strong commitment to customer satisfaction, we go
            above and beyond to exceed expectations. Our focus on innovation
            extends beyond our services, as we actively seek out opportunities
            to enhance social, economic, and moral responsibility.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
