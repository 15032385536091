import React from "react";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import "../css/footer.css"; // Import the CSS file

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="social-icons">
          <a
            href="https://www.facebook.com/benneycustomengines1"
            target="_blank"
            rel="noopener noreferrer"
            className="visually-hidden"
          >
            Visit Benney's Custom Engines Facebook Page
          </a>
          <FaFacebook aria-hidden="true" />

          <a
            href="https://www.instagram.com/benneyscustomengines"
            target="_blank"
            rel="noopener noreferrer"
            className="visually-hidden"
          >
            Visit Benney's Custom Engines Instagram Page
          </a>
          <FaInstagram aria-hidden="true" />
        </div>
        <div className="business-info">
          <span>&copy; {currentYear} Benney's Custom Engines</span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
