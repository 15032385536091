import React from "react";

// Authorized Dealer Company Logos
import ARP from "../img/ARP.jpeg";
import brodix from "../img/brodix.jpeg";
import callies from "../img/callies.png";
import compcams from "../img/compcams.jpeg";
import dart from "../img/dart.jpeg";
import diamond from "../img/Diamond.jpg";
import holley from "../img/holley.jpeg";
import je from "../img/je.png";
import jesel from "../img/jesel.jpeg";
import manley from "../img/manley.jpeg";
import schaeffers from "../img/schaeffers.jpeg";
import srp from "../img/srp.jpeg";
import stefs from "../img/stefs.png";
import tbs from "../img/tbs.png";
import wiseco from "../img/wiseco.png";

const Services = () => {
  const services = [
    {
      id: 1,
      title: "Precision Engine Machining and Balancing",
      description:
        "High-quality engine machining and computerized balancing services.",
    },
    {
      id: 2,
      title: "Engine Rebuilding and Repair",
      description:
        "Expert engine rebuilding, repair, and custom design services.",
    },
    {
      id: 3,
      title: "Dyno Testing and Tuning",
      description: "Dyno testing and tuning to optimize engine performance.",
    },
    {
      id: 4,
      title: "EFI Conversions and Repairs",
      description:
        "Electronic Fuel Injection (EFI) system conversions and repairs.",
    },
    {
      id: 5,
      title: "Powertrain Restoration",
      description: "Restoration of classic and muscle car powertrains.",
    },
    {
      id: 6,
      title: "General Car Repairs",
      description:
        "General repairs and maintenance for classic and muscle cars.",
    },
    {
      id: 7,
      title: "High-Horsepower Pump Gas Engines",
      description: "Specializing in high-horsepower pump gas engine builds.",
    },
    {
      id: 8,
      title: "Grudge Race Engines",
      description:
        "Discreet and high-performance engine builds for the Grudge Race community.",
    },
    // {
    //   id: 9,
    //   title: "Car Storage",
    //   description:
    //     "Monthly storage for classic and muscle cars in a clean, dry environment.",
    // },
  ];

  const authorizedDealers = [
    {
      id: 1,
      name: "ARP",
      imageUrl: ARP,
    },
    {
      id: 2,
      name: "Brodix",
      imageUrl: brodix,
    },
    {
      id: 3,
      name: "Callies",
      imageUrl: callies,
    },
    {
      id: 4,
      name: "Comp Cams",
      imageUrl: compcams,
    },
    {
      id: 5,
      name: "Dart",
      imageUrl: dart,
    },
    {
      id: 6,
      name: "Diamond Pistons",
      imageUrl: diamond,
    },
    {
      id: 7,
      name: "Holley",
      imageUrl: holley,
    },
    {
      id: 8,
      name: "JE Pistons",
      imageUrl: je,
    },
    {
      id: 9,
      name: "Jesel",
      imageUrl: jesel,
    },
    {
      id: 10,
      name: "Manley",
      imageUrl: manley,
    },
    {
      id: 11,
      name: "Schaeffers",
      imageUrl: schaeffers,
    },
    {
      id: 12,
      name: "SRP",
      imageUrl: srp,
    },
    {
      id: 13,
      name: "Stef's",
      imageUrl: stefs,
    },
    {
      id: 14,
      name: "The Blower Shop",
      imageUrl: tbs,
    },
    {
      id: 15,
      name: "Wiseco",
      imageUrl: wiseco,
    },
    // Add more authorized dealers with their respective image URLs
  ];

  return (
    <div className="container mt-4">
      <h1 className="text-center">Our Services</h1>
      <hr />

      {/* Services List */}
      <div className="row">
        <div className="col-md-8">
          <h2>Our Services</h2>
          <ul className="list-group">
            {services.map((service) => (
              <li className="list-group-item" key={service.id}>
                <h4>{service.title}</h4>
                <p>{service.description}</p>
              </li>
            ))}
          </ul>
        </div>

        {/* Company Logos */}
        <div className="col-md-4">
          <h2>Authorized Dealer</h2>
          <div className="d-flex flex-wrap justify-content-center">
            {authorizedDealers.map((authorizedDealers) => (
              <div key={authorizedDealers.id} className="p-3">
                <img
                  src={authorizedDealers.imageUrl}
                  alt={authorizedDealers.name}
                  className="img-fluid img-thumbnail"
                  style={{ maxWidth: '50%', height: 'auto'}}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;