import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './css/App.css';

import Header from "../src/components/Header";
import Footer from '../src/components/Footer';

import Home from '../src/components/Home';
import About from '../src/components/About';
import Services from '../src/components/Services';
import Projects from '../src/components/Projects';
// import Dyno from '../src/components/Dyno';
import Contact from '../src/components/Contact';


const App = () => {
  return (
    <Router>
      <div>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/projects" element={<Projects />} />
          {/* <Route path="/dyno-videos" element={<Dyno />} /> */}
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

export default App;

